import React from 'react'

const ModalNewEmployee = ({ isOpen, onClose, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal overflow-x-hidden height-800">
        {children}
      </div>
    </div>
  );
};


export default ModalNewEmployee