import React, { useEffect, useState } from 'react';

import serviceBlackImage from '../../assets/images/service-black.png';
import serviceWhiteImage from '../../assets/images/service-white.png';

import typesCarBlackImage from '../../assets/images/types-car-black.png';
import typesCarWhiteImage from '../../assets/images/types-car-white.png';

import reportBlackImage from '../../assets/images/report-black.png';
import reportWhiteImage from '../../assets/images/report-white.png';

import settingsBlackImage from '../../assets/images/settings-black.png';
import settingsWhiteImage from '../../assets/images/settings-white.png';

import brandBlackImage from '../../assets/images/brand-black.png';
import brandWhiteImage from '../../assets/images/brand-white.png';

import logOutImage from '../../assets/images/logout-dashboard.png';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const CarvashSidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [userCount, setUserCount] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_URL}/customer/user-count-online`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
                    },
                });
                setUserCount(response.data.user_count);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();

        const intervalId = setInterval(() => {
            fetchData();
        }, 5000);

        return () => clearInterval(intervalId);
    }, []);

    const handleLogout = () => {
        localStorage.clear();
        navigate('/login-management');
    };

    const isServicesPath = /\/carvash-dashboard\/services/.test(location.pathname);
    const isTypesPath = /\/carvash-dashboard\/types/.test(location.pathname);
    const isBrandPath = /\/carvash-dashboard\/brands/.test(location.pathname);
    const isReportsPath = /\/carvash-dashboard\/reports/.test(location.pathname);
    const isSettingsPath = /\/carvash-dashboard\/settings/.test(location.pathname);

    return (
        <div className='dashboard-sidebar'>
            <div className='dashboard-sidebar-container'>

                <div className='sidebar-user-online'>
                    <div className='py-20 space-around flx-column justify-content-space-between height-100'>
                        <h2 className='color-white'>خوش آمدید</h2>
                        
                        <div>
                            <div className='fs-18 f-700'>آخرین همسانسازی:</div>
                            <div className='fs-18 f-700'>سه شنبه، ۱۴ خرداد | ساعت ۱۸:۳۰</div>
                        </div>
                    </div>
                </div>

                <div className='mt-24'>
                    <div className={`link-item fs-20 pointer ${isServicesPath ? 'link-item-active' : ''}`} onClick={() => navigate('/carvash-dashboard/services')}>
                        <img src={isServicesPath ? serviceWhiteImage : serviceBlackImage} alt="services Images" className='ml-8' />
                        خدمات
                    </div>

                    <div className={`link-item fs-20 pointer ${isTypesPath ? 'link-item-active' : ''}`} onClick={() => navigate('/carvash-dashboard/types')}>
                        <img src={isTypesPath ? typesCarWhiteImage : typesCarBlackImage} alt="types Images" className='ml-8' />
                        تیپ ماشین
                    </div>

                    <div className={`link-item fs-20 pointer ${isBrandPath ? 'link-item-active' : ''}`} onClick={() => navigate('/carvash-dashboard/brands')}>
                        <img src={isBrandPath ? brandWhiteImage : brandBlackImage} alt="types Images" className='ml-8' />
                        برند خودرو
                    </div>

                    <div className={`link-item fs-20 pointer ${isReportsPath ? 'link-item-active' : ''}`} onClick={() => navigate('/carvash-dashboard/reports')}>
                        <img src={isReportsPath ? reportWhiteImage : reportBlackImage} alt="reports Images" className='ml-8' />
                        گزارشات
                    </div>

                    <div className={`link-item fs-20 pointer ${isSettingsPath ? 'link-item-active' : ''}`} onClick={() => navigate('/carvash-dashboard/settings')}>
                        <img src={isSettingsPath ? settingsWhiteImage : settingsBlackImage} alt="settings Images" className='ml-8' />
                        تنظیمات
                    </div>
                </div>

                <div className='d-flex sidebar-logout pointer' onClick={handleLogout}>
                    <img src={logOutImage} alt="Logout Images" />
                    <h3 className='mr-12'>خروج از حساب کاربری</h3>
                </div>
            </div>
        </div>
    );
};

export default CarvashSidebar;
