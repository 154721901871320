import React, { useState } from 'react';

const ServiceList = ({ selectedCategory, selectedServices, setSelectedServices }) => {

    // const [services] = useState([
    //     { "service_id": 1, "category_id": 1, "title": "اسپرسو", "price": 55000, "picture": require('../../assets/images/service-list-16.jpg') },
    //     { "service_id": 2, "category_id": 1, "title": "آمریکانو", "price": 55000, "picture": require('../../assets/images/service-list-17.jpg') },
    //     { "service_id": 3, "category_id": 1, "title": "کاپوچینو", "price": 75000, "picture": require('../../assets/images/service-list-18.jpg') },
    //     { "service_id": 4, "category_id": 1, "title": "لته", "price": 85000, "picture": require('../../assets/images/service-list-19.jpg') },



    //     { "service_id": 5, "category_id": 2, "title": "آیس آمریکانو", "price": 55000, "picture": require('../../assets/images/service-list-20.jpg') },
    //     { "service_id": 6, "category_id": 2, "title": "آیس لته", "price": 85000, "picture": require('../../assets/images/service-list-21.jpg') },
    //     { "service_id": 7, "category_id": 2, "title": "آیس موکا", "price": 100000, "picture": require('../../assets/images/service-list-22.jpg') },
    //     { "service_id": 8, "category_id": 2, "title": "آفو گاتو", "price": 80000, "picture": require('../../assets/images/service-list-23.jpg') },


    //     { "service_id": 9, "category_id": 3, "title": "نوتلا", "price": 110000, "picture": require('../../assets/images/service-list-24.jpg') },
    //     { "service_id": 10, "category_id": 3, "title": "قهوه و بادام زمینی", "price": 130000, "picture": require('../../assets/images/service-list-25.jpg') },
    //     { "service_id": 11, "category_id": 3, "title": "توت فرنگی و شکلات", "price": 140000, "picture": require('../../assets/images/service-list-26.jpg') },


    // ]);


    const [services] = useState(
        [
            {
                "car_service_id": 23,
                "name": "sdfsdfsdf",
                "price": 255,
                "description": "werwerwer",
                "image": require('../../assets/images/service-list-16.jpg')
            },
            {
                "car_service_id": 24,
                "name": "sdfsdfsdf",
                "price": 255,
                "description": "werwerwer",
                "image": require('../../assets/images/service-list-16.jpg')
            },
            {
                "car_service_id": 26,
                "name": "شیشسی",
                "price": 234,
                "description": "شسیشسیشسیشسیشسی",
                "image": require('../../assets/images/service-list-16.jpg')
            },
            {
                "car_service_id": 25,
                "name": "شیشسی",
                "price": 234,
                "description": "شسیشسیشسیشسیشسی",
                "image": require('../../assets/images/service-list-16.jpg')
            }
        ]
    )


    const addToSelectedServices = (service) => {
        const isServiceSelected = selectedServices.some(selectedService => selectedService.car_service_id === service.car_service_id);
        if (!isServiceSelected) {
            setSelectedServices(prevSelectedServices => [...prevSelectedServices, service]);
        } else {
            alert('این خدمت قبلاً انتخاب شده است!');
        }
    };

    const filteredServices = services.filter(service => service.category_id === selectedCategory);


    return (
        <div className='service-list boxes'>
            <div className='container flex-wrap'>
                {services.map((service, index) => (
                    <div className='box' key={index}>
                        <img src={service.image} alt='img service list' className='img-service' />
                        <div className='d-flex justify-content-space-between align-items-center mt-20 mb-16'>
                            <div className='fs-20 fw-700'>{service.name}</div>
                            <div className='d-flex align-items-center price fs-24 fw-700'>{service.price.toLocaleString()}<div className='text-gray fs-12 mr-3'>تومان</div></div>
                        </div>
                        <button className='service-list-button bg-button-blue pointer' onClick={() => addToSelectedServices(service)}>انتخاب محصول</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ServiceList;