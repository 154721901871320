import React, { useState } from 'react'
import ServiceList from './ServiceList';
import FactorList from './FactorList';
import Header from './Header';
const Home = () => {
    const [selectedServices, setSelectedServices] = useState([]);
    return (
        <div>
            <Header />
            <div className='d-flex justify-content-center'>
                <ServiceList setSelectedServices={setSelectedServices} selectedServices={selectedServices}  />
                <FactorList setSelectedServices={setSelectedServices} selectedServices={selectedServices} />
            </div>
        </div>
    )
}

export default Home