import React from 'react'

const Reports = () => {
    return (
        <div>
            <div className='dashboard-navbar'>
                <div className='title-40 f-700'>گزارشات</div>
            </div>


            <div>
                <div className='fs-24 f-700 mt-40 mb-8'>پرفروش ترین ها</div>

                <div className='d-flex report justify-content-space-between'>
                    <div className='box d-flex flex-direction-column justify-content-space-between'>
                        <div className='d-flex justify-content-space-between'>
                            <div className='color-sub'>پرفروش ترین آیتم</div>
                            <div className='f-700 color-white'>30 روز گذشته</div>
                        </div>

                        <div className='d-flex justify-content-space-between'>
                            <div className='fs-24 f-700 color-white'>پیتزا آلفردو</div>
                            <div className='fs-24 f-700 color-white'>350 عدد</div>
                        </div>
                    </div>
                    
                    
                    <div className='box d-flex flex-direction-column justify-content-space-between'>
                        <div className='d-flex justify-content-space-between'>
                            <div className='color-sub'>پرفروش ترین منو</div>
                            <div className='f-700 color-white'>30 روز گذشته</div>
                        </div>

                        <div className='d-flex justify-content-space-between'>
                            <div className='fs-24 f-700 color-white'>پیتزا آلفردو</div>
                            <div className='fs-24 f-700 color-white'>350 عدد</div>
                        </div>
                    </div>

                    <div className='box d-flex flex-direction-column justify-content-space-between'>
                        <div className='d-flex justify-content-space-between'>
                            <div className='color-sub'>پرفروش ترین افزودنی </div>
                            <div className='f-700 color-white'>30 روز گذشته</div>
                        </div>

                        <div className='d-flex justify-content-space-between'>
                            <div className='fs-24 f-700 color-white'>پیتزا آلفردو</div>
                            <div className='fs-24 f-700 color-white'>350 عدد</div>
                        </div>
                    </div>
                </div>
            </div>


            <div>
            <div className='fs-24 f-700 mt-40 mb-8'>نمودار</div>
            </div>
        </div>
    )
}

export default Reports