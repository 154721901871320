import React from 'react'
import SalonSidebar from './SalonSidebar';
import { Routes, Route } from "react-router-dom";
import Settings from '../../components/SalonComponents/Settings';
import Services from '../../components/SalonComponents/Services';
import TypeCarDetail from '../../components/SalonComponents/TypeCarDetail';
import Reports from '../../components/SalonComponents/Reports';
import ServiceDetail from '../../components/SalonComponents/ServiceDetail';
import Employees from '../../components/SalonComponents/Employees';




const SalonDashboard = () => {
    return (
        <div className='dashboard-main'>
            <SalonSidebar />
            <div className='dashboard-main-container'>
                <Routes>
                    <Route path="/" element={<Services />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/service-detail/:id" element={<ServiceDetail />} />
                    <Route path="/employees" element={<Employees />} />
                    <Route path="/type-detail/:id" element={<TypeCarDetail />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/reports" element={<Reports />} />

                </Routes>
            </div>
        </div>
    )
}

export default SalonDashboard