

  import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import chaharDoorImg from '../../assets/images/4door.png';
import benzImg from '../../assets/images/benz.png';
import paymentWaitingImg from '../../assets/images/payment-waiting.png';
import paymentSuccessImg from '../../assets/images/payment-success.png';
import paymentNotSuccessImg from '../../assets/images/payment-not-success.png';
import ServiceList from './ServiceList';
import FactorList from './FactorList';
import Header from './Header';

const Intro = () => {
  const [carTypes, setCarTypes] = useState([
    {
      "car_type_id": 3,
      "name_main": "سایر",
      "brands": [
        {
          "car_brand_id": 1,
          "name": "mzda",
          "image": "/images/carwash/brand/aG4CRRGivJIFVAEpsWwgZqqU3pJK0X7iDW0qvbCa.png",
          "car_tip_id": 10
        },
        {
          "car_brand_id": 2,
          "name": "second",
          "image": null,
          "car_tip_id": 10
        },
        {
          "car_brand_id": 1,
          "name": "mzda",
          "image": "/images/carwash/brand/aG4CRRGivJIFVAEpsWwgZqqU3pJK0X7iDW0qvbCa.png",
          "car_tip_id": 3
        }
      ]
    },
    {
      "car_type_id": 2,
      "name_main": "شاستی ا بلند",
      "brands": [
        {
          "car_brand_id": 1,
          "name": "mzda",
          "image": "/images/carwash/brand/aG4CRRGivJIFVAEpsWwgZqqU3pJK0X7iDW0qvbCa.png",
          "car_tip_id": 10
        },
        {
          "car_brand_id": 2,
          "name": "second",
          "image": null,
          "car_tip_id": 10
        }
      ]
    },
    {
      "car_type_id": 1,
      "name_main": "چهاردر",
      "brands": [
        {
          "car_brand_id": 1,
          "name": "mzda",
          "image": "/images/carwash/brand/aG4CRRGivJIFVAEpsWwgZqqU3pJK0X7iDW0qvbCa.png",
          "car_tip_id": 10
        },
        {
          "car_brand_id": 2,
          "name": "second",
          "image": null,
          "car_tip_id": 10
        },
        {
          "car_brand_id": 2,
          "name": "second",
          "image": null,
          "car_tip_id": 3
        }
      ]
    }
  ]);
  const [selectedCarType, setSelectedCarType] = useState(null);
  const [showSecondStep, setShowSecondStep] = useState(false);
  const navigate = useNavigate();


  const handleCarTypeClick = (carType) => {
    setSelectedCarType(carType);
    setShowSecondStep(true);
  };

  const handleBrandClick = (carTipId) => {
    navigate(`/service/${carTipId}`);
  };

  const handleBackClick = () => {
    window.location.reload(); // This will refresh the page
  };

  return (
    <>
      <div className='carvash-client height-100vh'>
        {!showSecondStep ? (
          <div className='first-step'>
            <div className='container'>
              <div className='fs-56 color-white fw-700 mb-40'>لطفا نوع ماشین خود را انتخاب کنید</div>
              {carTypes.map((carType) => (
                <div
                  className='d-flex align-items-center justify-content-space-between box'
                  key={carType.car_type_id}
                  onClick={() => handleCarTypeClick(carType)}
                >
                  <div className='fs-40 fw-700'>{carType.name_main}</div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className='second-step'>
            <div className='container'>
              <div className='d-flex justify-content-space-between'>
                <div className='fs-56 color-white fw-700 mb-40'>برند ماشین خود را انتخاب کنید</div>
                <div className='back-navbar-car fw-700' onClick={handleBackClick}>بازگشت</div>
              </div>
              {selectedCarType.brands.map((brand) => (
                <div
                  className='d-flex align-items-center justify-content-space-between box'
                  key={brand.car_brand_id}
                  onClick={() => handleBrandClick(brand.car_tip_id)}
                >
                  <div className='d-flex align-items-center fs-40 fw-700'>
                    <img
                      className="imgS ml-32"
                      src={brand.image}
                      alt="logo"
                    /> {brand.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Intro;




      {/* <div>
        <Header setSelectedCategory={setSelectedCategory} selectedCategory={selectedCategory} />
        <div className='d-flex justify-content-center'>
          <ServiceList setSelectedServices={setSelectedServices} selectedServices={selectedServices} selectedCategory={selectedCategory} />
          <FactorList setSelectedServices={setSelectedServices} selectedServices={selectedServices} />
        </div>
      </div> */}


      {/* <div className='carvash-client height-100vh'>
        <div className='d-flex flex-direction-column justify-content-center pay-waiting-step height-100vh'>
          <div className='container'>

            <div className='d-flex justify-content-center align-items-center box h-720'>
              <div className='d-flex flex-direction-column align-items-center'>
                <div>
                  <img
                    src={paymentWaitingImg}
                    alt="logo"
                    className='mb-24'
                  />
                </div>
                <div className='fs-56 fw-700'>
                لطفا پرداخت خود را انجام دهید
                </div>
              </div>
              <div>
              </div>
            </div>

          </div>
        </div>
      </div> */}


      {/* <div className='carvash-client height-100vh'>
        <div className='d-flex flex-direction-column justify-content-center pay-success-step height-100vh'>
          <div className='container'>

            <div className='d-flex justify-content-center align-items-center box h-720'>
              <div className='d-flex flex-direction-column align-items-center'>
                <div>
                  <img
                    src={paymentSuccessImg}
                    alt="logo"
                    className='mb-24'
                  />
                </div>
                <div className='fs-56 fw-700 pay-success'>
                پرداخت با موفقیت انجام شد
                </div>
              </div>
              <div>
              </div>
            </div>

          </div>
        </div>
      </div> */}


      {/* <div className='carvash-client height-100vh'>
        <div className='d-flex flex-direction-column justify-content-center pay-success-step height-100vh'>
          <div className='container'>

            <div className='d-flex justify-content-center align-items-center box h-720'>
              <div className='d-flex flex-direction-column align-items-center'>
                <div>
                  <img
                    src={paymentNotSuccessImg}
                    alt="logo"
                    className='mb-24'
                  />
                </div>
                <div className='fs-56 fw-700 pay-not-success'>
                پرداخت موفقیت آمیز نبود!
                </div>
              </div>
              <div>
              </div>
            </div>

          </div>
        </div>
      </div> */}