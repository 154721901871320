import React from 'react'
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const Reports = () => {
    // داده‌های نمونه برای نمودار
    const salesData = {
        labels: ['1402/01/01', '1402/01/02', '1402/01/03', '1402/01/04', '1402/01/05'],
        datasets: [
            {
                label: 'تعداد فروش',
                data: [10, 20, 15, 25, 1],
                fill: false,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: '#2C67F5',
                tension: 0.4, 
            },
        ],
    };

    const options = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'تاریخ (شمسی)',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'تعداد فروش',
                },
                beginAtZero: true,
                // grid: {
                //     display: false,  // حذف خطوط شبکه در محور X
                // },
            },
        },
    };

    return (
        <div>
            <div className='dashboard-navbar'>
                <div className='title-40 f-700'>گزارشات</div>
            </div>

            <div>
                <div className='fs-24 f-700 mt-40 mb-8'>پرفروش ترین ها</div>

                <div className='d-flex report justify-content-space-between'>
                    <div className='box d-flex flex-direction-column justify-content-space-between'>
                        <div className='d-flex justify-content-space-between'>
                            <div className='color-sub'>پرفروش ترین آیتم</div>
                            <div className='f-700 color-white'>30 روز گذشته</div>
                        </div>

                        <div className='d-flex justify-content-space-between'>
                            <div className='fs-24 f-700 color-white'>پیتزا آلفردو</div>
                            <div className='fs-24 f-700 color-white'>350 عدد</div>
                        </div>
                    </div>

                    <div className='box d-flex flex-direction-column justify-content-space-between'>
                        <div className='d-flex justify-content-space-between'>
                            <div className='color-sub'>پرفروش ترین منو</div>
                            <div className='f-700 color-white'>30 روز گذشته</div>
                        </div>

                        <div className='d-flex justify-content-space-between'>
                            <div className='fs-24 f-700 color-white'>پیتزا آلفردو</div>
                            <div className='fs-24 f-700 color-white'>350 عدد</div>
                        </div>
                    </div>

                    <div className='box d-flex flex-direction-column justify-content-space-between'>
                        <div className='d-flex justify-content-space-between'>
                            <div className='color-sub'>پرفروش ترین افزودنی </div>
                            <div className='f-700 color-white'>30 روز گذشته</div>
                        </div>

                        <div className='d-flex justify-content-space-between'>
                            <div className='fs-24 f-700 color-white'>پیتزا آلفردو</div>
                            <div className='fs-24 f-700 color-white'>350 عدد</div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className='fs-24 f-700 mt-40 mb-8'>نمودار</div>

                <Line data={salesData} options={options} />
            </div>
        </div>
    )
}

export default Reports
