// AppRouter.js
import { BrowserRouter, Router, Route, Routes, Navigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from './pages/Login';
import Verify from './pages/Verify';
import CarvashDashboard from './pages/CarvashDashboard/CarvashDashboard';
import FastFoodDashboard from './pages/FastFoodDashboard/FastFoodDashboard';
import SalonDashboard from './pages/SalonDashboard/SalonDashboard';
import FastFoodClient from './pages/FastFoodClient/FastFoodClient';
import CarvashClient from './pages/CarvashClient/CarvashClient';
import SalonClient from './pages/SalonClient/SalonClient';

const PrivateSecretaryRoute = ({ element: Element, ...rest }) => {
  const { state } = useAuth();

  if (!state.secretaryToken) {
    // Redirect to the login page if the user is not authenticated
    return <Navigate to="/login-management" />;
  }

  // Render the component if the user is authenticated
  return <Element {...rest} />;
};

const PrivateAdminRoute = ({ element: Element, ...rest }) => {
  const { state } = useAuth();

  if (!state.adminToken) {
    // Redirect to the login page if the user is not authenticated
    return <Navigate to="/login-management" />;
  }

  // Render the component if the user is authenticated
  return <Element {...rest} />;
};


const PrivateUserRoute = ({ element: Element, ...rest }) => {
  const { state } = useAuth();

  if (!state.userToken) {
    // Redirect to the login page if the user is not authenticated
    return <Navigate to="/login" />;
  }

  // Render the component if the user is authenticated
  return <Element {...rest} />;
};


const App = () => {
  return (
    <BrowserRouter>
      <ToastContainer autoClose={8000} />
      <Routes>
        {/* <Route path="/secretary-dashboard/*" element={<PrivateSecretaryRoute element={SecretaryDashboard} />} /> */}
        <Route path="/carvash-dashboard/*"  element={<CarvashDashboard />} /> 
        <Route path="/fastfood-dashboard/*"  element={<FastFoodDashboard />} /> 
        <Route path="/salon-dashboard/*"  element={<SalonDashboard />} /> 
        {/* <Route path="/admin-dashboard/*" element={<PrivateAdminRoute element={AdminDashboard} />} />
        <Route path="/user-dashboard/*" element={<PrivateUserRoute element={UserDashBoard} />} /> */}

        {/* <Route path="/" element={<FastFoodClient />} /> */}
        {/* <Route path="/" element={<CarvashClient />} /> */}
        <Route path="/*" element={<CarvashClient />} />

        <Route path="/login" element={<Login />} />
        <Route path="/verify" element={<Verify />} />
        {/* <Route path="admin-dashboard/*" element={<AdminDashboard />} />





        <Route path="/user" element={<UserDashBoard />} role="user" />
        <Route path="/user/meetings" element={<UserMeetings />} role="user" />
        <Route path="/user/info" element={<UserInfo />} role="user" />
        <Route path="/secretary" element={<PrivateRoute element={<SecretaryDashboard />} role="secretary" />} />

        <Route path="/admin-dashboard" element={<AdminDashboard />} />
        <Route path="/user-detail" element={<UserDetail />} />
        <Route path="/users-list" element={<UsersList />} />
        <Route path="/user-credit" element={<UserCreditExtending />} />
        <Route path="/" element={<Navigate to="/login" />} /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
