import React, { useState, useEffect } from 'react';
import axios from 'axios';
import leftArrowImage from '../../assets/images/left-arrow.png';
import ImgUploadImage from '../../assets/images/file_upload.png';

import { NavLink } from "react-router-dom";
import Loading from '../../utils/Loading';
import { toast } from 'react-toastify';
import ModalNewEmployee from './ModalNewEmployee';

import convertToEnglishDigit from '../../utils/convertToEnglishDigit';

const Employees = () => {
    const [employeeData, setEmployeeData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [isModalOpen, setModalOpen] = useState(false);

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        code_meli: '',
        birthday: '',
        mobile: '',
        user_name: '',
        password: '',
        role: 'secretary',
        image: null,
    });

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };
    //End Modal


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setFormData({ ...formData, image: file });
    };

    const handleRoleChange = (e) => {
        const { value } = e.target;
        setFormData({ ...formData, role: value });
    };

    const handleDateChange = (dateObjects) => {

        const formattedDate = dateObjects.format();
        const englishDigitsDate = convertToEnglishDigit(formattedDate);
        setFormData((prevData) => ({ ...prevData, birthday: englishDigitsDate }));
    };

    const handleSubmit = async () => {
        try {
            setIsSubmitting(true);
            const formDataObject = new FormData();
            Object.keys(formData).forEach((key) => {
                formDataObject.append(key, formData[key]);
            });
            const response = await axios.post(
                `${process.env.REACT_APP_URL}/admin/add-employee`,
                formDataObject,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
                    },
                }
            );


            handleCloseModal();

            setTimeout(() => {
                window.location.reload();
            }, 3000);

            toast.success(response.data.success, { autoClose: 15000 });

        } catch (error) {
            console.error(error);
            toast.error(error.response.data.error, { autoClose: 15000 });

        } finally {
            setIsSubmitting(false);
        }
    };


    useEffect(() => {
        fetchData();
    }, [currentPage]);

    const fetchData = async () => {
        setIsLoading(true)
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/admin/employee-list?page=${currentPage}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
                },
            });

            const { employeeList } = response.data;
            setEmployeeData(employeeList.data);
            setTotalPages(employeeList.last_page);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false)
        }
    };

    const handlePagination = (page) => {
        setCurrentPage(page);
    };


    console.log(formData);

    return (
        <>
            {isLoading ? <Loading /> :
                <div>
                    <ModalNewEmployee isOpen={isModalOpen} onClose={handleCloseModal}>
                        <div className="header">
                            <h2>ایجاد کارمند جدید</h2>
                        </div>


                        <div className='container'>
                            <div className='d-flex justify-content-center'>
                                <label htmlFor="image-upload" className='upload-img d-flex align-items-center justify-content-center pointer'>
                                    {formData.image ? (
                                        <img src={URL.createObjectURL(formData.image)} alt="Selected Image" className='pointer upload-img' width={32} height={32} />
                                    ) : (
                                        <div className='d-flex align-items-center flex-direction-column'>
                                            <img src={ImgUploadImage} alt="Profile Images" className='pointer' width={32} height={32} />
                                            <span>آپلود تصویر</span>
                                        </div>
                                    )}
                                </label>
                                <input
                                    type="file"
                                    id="image-upload"
                                    name="image"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleImageChange}
                                />
                            </div>

                            <div>
                                <h2 className='mb-8'>اطلاعات خدمت</h2>

                                <div className='f-500 fs-16 mb-4'>نام کارمند</div>
                                <input
                                    type="text"
                                    name="first_name"
                                    className="width-480 input-form-dashboard-step mb-24"
                                    placeholder='نام کارمند'
                                    value={formData.first_name}
                                    onChange={handleChange}
                                />

                                <div className='f-500 fs-16 mb-4'>نام خانوادگی کارمند</div>
                                <input
                                    type="text"
                                    name="first_name"
                                    className="width-480 input-form-dashboard-step mb-24"
                                    placeholder='نام خانوادگی کارمند'
                                    value={formData.first_name}
                                    onChange={handleChange}
                                />

                                <div className='f-500 fs-16 mb-4'>شماره موبایل کارمند</div>
                                <input
                                    type="text"
                                    name="first_name"
                                    className="width-480 input-form-dashboard-step mb-24"
                                    placeholder='شماره موبایل کارمند'
                                    value={formData.first_name}
                                    onChange={handleChange}
                                />

                                <div className='f-500 fs-16 mb-4'>کد ملی کارمند</div>
                                <input
                                    type="text"
                                    name="first_name"
                                    className="width-480 input-form-dashboard-step mb-24"
                                    placeholder='کد ملی کارمند'
                                    value={formData.first_name}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className='mt-24'>
                                <h2>خدمات</h2>
                                <div className='box width-480 mb-8'>
                                <p className='fs-14 mb-8'>برای برند، نوع ماشینی را که در  این تیپ قرار میگیرد را انتخاب کنید</p>
                                    
                                <div>
                                        <span>سدان</span>
                                    </div>

                                    <div>
                                        <span>سدان</span>
                                    </div>

                                    <div>
                                        <span>سدان</span>
                                    </div>

                                </div>
                            </div>
                        </div>



                        <div className='footer'>
                            <div className='d-flex flx-start'>
                                <div className="form-btn f-700 width-130 ml-16" onClick={handleSubmit} disabled={isSubmitting}>{isSubmitting ? 'در حال ارسال' : 'ایجاد خدمت'}</div>
                                <div className="form-btn f-700 width-130 form-btn-transparent" onClick={handleCloseModal}>منصرف شدم</div>
                            </div>
                        </div>
                    </ModalNewEmployee>


                    <div className='dashboard-navbar nav-search-row'>
                        <div className='title-40 f-700'>کارمندان</div>


                        <div>
                            <div className="button-login" onClick={handleOpenModal}>
                                ایجاد کارمند جدید
                            </div></div>
                    </div>

                    <div className='table-container'>
                        <div className="main-container">
                            <div className="table-row heading-row">
                                <div className="row-item heading-row f-700">نام کارمند</div>
                                <div className="row-item heading-row f-700">شماره تماس</div>
                                <div className="row-item justify-content-left">

                                </div>
                            </div>


                            <div className="table-row">
                                <div className="row-item f-700">
                                    محمد رضایی
                                </div>

                                <div className="row-item f-700">
                                    09124444444
                                </div>

                                <div className="row-item justify-content-left">
                                    <NavLink to={`/salon-dashboard/type-detail/9`}>
                                        <img src={leftArrowImage} alt="Arrow Images" className='pointer' />
                                    </NavLink>
                                </div>
                            </div>

                            <div className="table-row">
                                <div className="row-item f-700">
                                    محمد محمدی
                                </div>

                                <div className="row-item f-700">
                                    09124566215
                                </div>

                                <div className="row-item justify-content-left">
                                    <NavLink to={`/salon-dashboard/type-detail/6`}>
                                        <img src={leftArrowImage} alt="Arrow Images" className='pointer' />
                                    </NavLink>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* Pagination */}
                    <div className="pagination-container">
                        {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
                            <button
                                key={page}
                                className={`pagination-button ${currentPage === page ? 'active' : ''}`}
                                onClick={() => handlePagination(page)}
                            >
                                {page}
                            </button>
                        ))}
                    </div>

                </div>
            }
        </>
    )
}

export default Employees
