
import React, { useEffect, useState } from 'react'
import notifactionNoneImage from '../../assets/images/notifications_none.png';
import confirmationNumberImage from '../../assets/images/confirmation_number.png';

import convertToEnglishDigit from '../../utils/convertToEnglishDigit';


import { useNavigate, useParams } from 'react-router';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loading from '../../utils/Loading';


const ServiceDetail = ({ token, linkPrefix }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [isEditing, setIsEditing] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [sessionsList, setSessionsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);



    const [formState, setFormState] = useState({
        first_name: '',
        last_name: '',
        father_name: '',
        gender: '',
        code_meli: '',
        birthday: '',
        mobile: '',
        address: '',
        weight: '',
        height: '',
        user_id: id
    });

    console.log(formState);

    const [membershipInfo, setMembershipInfo] = useState({
        end_date: '',
        name: '',
        session_count: '',
        membership_status: '',
        seance_id: ''
    });

    console.log(formState);

    useEffect(() => {

        const fetchUserData = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${process.env.REACT_APP_URL}/customer/info/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                console.log(response.data);

                setFormState({
                    ...formState,
                    first_name: response.data.customerInfo.first_name,
                    last_name: response.data.customerInfo.last_name,
                    father_name: response.data.customerInfo.father_name,
                    gender: response.data.customerInfo.gender,
                    birthday: response.data.customerInfo.birthday,
                    code_meli: response.data.customerInfo.code_meli,
                    mobile: response.data.customerInfo.mobile,
                    address: response.data.customerInfo.address,
                    weight: response.data.customerInfo.weight,
                    height: response.data.customerInfo.height,
                });
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setIsLoading(false);
            }
        };


        const fetchMembershipInfo = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${process.env.REACT_APP_URL}/secretary/get-membership-info/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                console.log(response.data);

                setMembershipInfo({
                    end_date: response.data.membershipInfo.end_date,
                    name: response.data.membershipInfo.name,
                    session_count: response.data.membershipInfo.session_count,
                    membership_status: response.data.membershipInfo.membership_status,
                    seance_id: response.data.membershipInfo.seance_id
                });
            } catch (error) {
                console.error('Error fetching membership info:', error);
            } finally {
                setIsLoading(false);
            }
        };

        const fetchSessionsList = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${process.env.REACT_APP_URL}/session/sessions-list-for-secretary/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                setSessionsList(response.data.sessionsList);
            } catch (error) {
                console.error('Error fetching sessions list:', error);
            } finally {
                setIsLoading(false);
            }
        };



        fetchUserData();
        fetchMembershipInfo();
        fetchSessionsList();

    }, [id]);


    console.log(sessionsList);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormState((prev) => ({ ...prev, [name]: value }));

        setIsEditing(true);
    };

    const handleSaveChanges = async () => {
        try {
            setIsSubmitting(true);
            const response = await axios.post(`${process.env.REACT_APP_URL}/customer/edit-info`, formState, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                },
            });
            toast.success(response.data.success, { autoClose: 15000 });
        } catch (error) {
            toast.error(error.response.data.error, { autoClose: 15000 });

        } finally {
            setIsSubmitting(false);
        }
    };

    const handleDeleteSession = async (sessionId) => {
        const confirmDelete = window.confirm('آیا از حذف جلسه مطمئن هستید؟');

        if (confirmDelete) {
            try {
                const response = await axios.delete(`${process.env.REACT_APP_URL}/session/delete/${id}/${sessionId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                setSessionsList((prevSessions) => {
                    const updatedSessions = prevSessions.map((session) => {
                        if (session.lists.some((list) => list.session_id === sessionId)) {
                            session.lists = session.lists.filter((list) => list.session_id !== sessionId);
                        }
                        return session;
                    });

                    return updatedSessions.filter((session) => session.lists.length > 0);
                });

                toast.success(response.data.success, { autoClose: 15000 });
            } catch (error) {
                console.error('Error deleting session:', error);
                toast.error(error.response.data.error, { autoClose: 15000 });
            }
        }
    };

    const handleDateChange = (dateObjects) => {
        const formattedDate = dateObjects.format();
        const englishDigitsDate = convertToEnglishDigit(formattedDate);
        setFormState((prevData) => ({ ...prevData, birthday: englishDigitsDate }));
    };


    return (
        <>
            {isLoading ? <Loading /> :
                <div>
                    <div className='dashboard-navbar space-between'>
                        <div className='title-40 f-700'>نام خدمت</div>
                        <div className='pl-16'>
                            <img
                                src={notifactionNoneImage}
                                alt="Notifaction"
                            /></div>
                    </div>

                    <div>
                        <div className="main-container d-flex">
                            <div className='user-nav-details width-316'>
                                <div className='item-nav-detail mb-21 pointer'>اطلاعات خدمت</div>
                                <hr />
                                <div className='item-nav-detail mt-21 mb-21 pointer'>تیپ های اضافه شده</div>
                            </div>

                            <div className=' flex-1'>


                                <div className='mt-0 mr-24 mb-32'>
                                    <div className='header-personal'></div>
                                    <div className='footer-personal height-auto relative'>
                                        <img
                                            // src={userPicImage}
                                            alt="User Pic"
                                            className='user-pic footer-personal-img absolute'
                                            // onClick={handleImageClick}
                                            width={80}
                                            height={80}
                                        />
                                        <input
                                            type="file"
                                            style={{ display: 'none' }}
                                        />
                                        <div className='d-flex justify-content-space-between height-100 footer-personal-body'>
                                            <div className='d-flex flex-direction-column mt-45'>
                                                <div className='fs-24 f-700'>نام خدمت</div>
                                                <div className='d-flex f-700 fs-20 color-sub mt-8'>

                                                    <div className='row-h-center sub-text fs-12'>
                                                        <img
                                                            src={confirmationNumberImage}
                                                            alt="Confirm Images"
                                                            className='ml-8'
                                                        />
                                                        خدمت توضیحات
                                                    </div>
                                                </div>

                                            </div>

                                            <div className='f-700 pointer'>...</div>
                                        </div>
                                    </div>
                                </div>


                                <div className='table-container mt-24 mr-24'>

                                    <div className='user-detail-container'>
                                        <h4 className='mb-8'>اطلاعات خدمت</h4>
                                        <div>

                                            <div className="form-content">
                                                <>

                                                    <div className='d-flex space-between'>
                                                        <div>
                                                            <div className='mb-6 f-700'> نام خدمت</div>
                                                            <input type="text" value={formState.first_name} onChange={handleInputChange} name="first_name" className="input-form-dashboard-step mb-24 width-316" placeholder='نام خدمت' />
                                                        </div>


                                                        <div>
                                                            <div className='mb-6 f-700'>قیمت</div>
                                                            <input type="text" value={formState.first_name} onChange={handleInputChange} name="first_name" className="input-form-dashboard-step mb-24 width-316" placeholder='قمیت (تومان)' />
                                                        </div>
                                                    </div>

                                                    <div className='d-flex'>

                                                        <div className='flex'>
                                                            <div className='mb-6 f-700'>توضیحات</div>
                                                            <textarea value={formState.first_name} onChange={handleInputChange} name="first_name" className="input-form-dashboard-step mb-24 width-100" placeholder='قمیت (تومان)' ></textarea>
                                                        </div>
                                                    </div>
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='table-container mr-24'>
                                    <div className='user-detail-container'>
                                        <h4 className='mb-4'>تیپ های افزوده شده</h4>
                                        <div>

                                            <div className="form-content">
                                                <>

                                                    <div className='d-flex'>
                                                        <div>
                                                        نام تیپ   
                                                         </div>
                                                    </div>

                                                </>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                
                                <div className='table-container mr-24'>
                                    <div className='user-detail-container'>
                                        <h4 className='mb-4'>خدمات</h4>
                                        <div>

                                            <div className="form-content">
                                                <>

                                                    <div className='d-flex'>
                                                        <div>
                                                        خدمات
                                                         </div>
                                                    </div>

                                                </>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ServiceDetail
