import React, { useEffect, useState } from 'react'
import serviceListImg from '../../assets/images/service-list-1.png';
import deleteImg from '../../assets/images/delete.png';
import Barcode from 'react-barcode';
import { useNavigate } from 'react-router';

const FactorList = ({ selectedServices, setSelectedServices }) => {
    const navigate = useNavigate();

    const calculateTotalPrice = () => {
        return selectedServices.reduce((total, service) => total + service.price, 0);
    };

    const printContent = () => {
        const printContents = document.querySelector('.print-page').innerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }

    useEffect(() => {
        const afterPrint = () => {
            window.location.href = '/';
        };

        window.onafterprint = afterPrint;

        return () => {
            window.onafterprint = null;
        };
    }, []);

    const removeFromSelectedServices = (serviceToRemove) => {
        const updatedServices = selectedServices.filter(service => service !== serviceToRemove);
        setSelectedServices(updatedServices);
    };

    const totalPrice = calculateTotalPrice();

    return (
        <div className='factor'>


            <div class="print-page justify-content-center">
                <div className='title-factor fw-700'>فاکتور پرداخت کافه بولوت</div>

                <div class="table-container">
                    <div class="row">
                        <div class="title fw-700">عنوان</div>
                        <div class="price fw-700">قیمت</div>
                    </div>
                    {selectedServices.map((service, index) => (
                        <div class="row">
                            <div class="title">{service.name}</div>
                            <div class="price">{service.price.toLocaleString()}</div>
                        </div>
                    ))}
                    <div class="row total-row">
                        <div class="title fs-12">مجموع:</div>
                        <div class="price fs-12">{totalPrice.toLocaleString()} تومان</div>
                    </div>
                </div>



                {/* <table className='mb-16 mr-16'>
                    <thead>
                        <tr>
      
                            <th>محصول</th>
                            <th>قیمت</th>
                        </tr>
                    </thead>
                    <tbody>
                    {selectedServices.map((service, index) => (
                        <tr>
                            <td>{service.title}</td>
                            <td>{service.price.toLocaleString()}</td>
                        </tr>
                         ))}
                    </tbody>
                    <tfoot>
                        <tr class="total">
                            <td >جمع قیمت</td>
                            <td>{totalPrice} تومان</td>
                        </tr>
                    </tfoot>
                </table>

                <div className='title-factor'>بارکد فیش را با دستگاه بارکدخوان اسکن کنید.</div> */}


                <div className='barcode'>
                    <Barcode value={totalPrice}
                        format="CODE128"
                        width="2"
                        height="30"
                        displayValue="false"
                    />
                </div>




            </div>



            <div className='container d-flex flex-direction-column justify-content-space-between h-100'>

                <div className='d-flex flex-direction-column overflow-auto'>
                    <div className='title'>فاکتور</div>

                    {selectedServices.map((service, index) => (
                        <div className='d-flex mt-42' key={index}>
                            <img src={service.image} alt='img service list' />
                            <div className='mr-16'>
                                <div className='fw-700 fs-20'>{service.name}</div>
                                <div className='fw-700 fs-24'>{service.price.toLocaleString()} تومان</div>

                                <div className='d-flex align-items-center'>
                                    <div className='fw-700 fs-20'>1</div>
                                    <div>
                                        <img
                                            src={deleteImg}
                                            alt='delete img'
                                            className='delete-img pointer'
                                            onClick={() => removeFromSelectedServices(service)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    ))}

                </div>


                <div className='factor-footer'>
                    <div className='d-flex justify-content-space-between mb-19'>
                        <div className='text-gray'>مبلغ کل</div>
                        <div className='fw-700 fs-16'>{totalPrice.toLocaleString()} تومان</div>
                    </div>

                    <div className='d-flex justify-content-space-between mb-19'>
                        <div className='text-gray'>مالیات 0 درصد</div>
                        <div className='fw-700 fs-16'>0 تومان</div>
                    </div>

                    <hr className='mb-19' />

                    <div className='d-flex justify-content-space-between mb-19'>
                        <div className='text-gray'>مبلغ قابل پرداخت</div>
                        <div className='fw-700 fs-16'>{totalPrice.toLocaleString()} تومان</div>
                    </div>


                    <button onClick={() => printContent()} className='service-list-button bg-button-black pointer'>پرداخت</button>


                </div>
            </div>
        </div>
    )
}

export default FactorList