import React from 'react'
import { Routes, Route } from "react-router-dom";
import Settings from '../../components/FastFoodComponents/Settings';
import FastFoodSidebar from './FastFoodSidebar';
import Items from '../../components/FastFoodComponents/Items';
import ItemDetail from '../../components/FastFoodComponents/ItemDetail';
import Menues from '../../components/FastFoodComponents/Menues';
import Additives from '../../components/FastFoodComponents/Additives';
import MenuesDetail from '../../components/FastFoodComponents/MenuesDetail';
import AdditivesDetail from '../../components/FastFoodComponents/AdditivesDetail';
import Reports from '../../components/FastFoodComponents/Reports';





const FastFoodDashboard = () => {
    return (
        <div className='dashboard-main'>
            <FastFoodSidebar />
            <div className='dashboard-main-container'>
                <Routes>
                    <Route path="/" element={<Items />} />
                    <Route path="/items" element={<Items />} />
                    <Route path="/item-detail/:id" element={<ItemDetail />} />
                    <Route path="/menues" element={<Menues />} />
                    <Route path="/menues-detail/:id" element={<MenuesDetail />} />
                    <Route path="/additives" element={<Additives />} />
                    <Route path="/additive-detail/:id" element={<AdditivesDetail />} />
                    <Route path="/reports" element={<Reports />} />
                    <Route path="/settings" element={<Settings />} />

                </Routes>
            </div>
        </div>
    )
}

export default FastFoodDashboard