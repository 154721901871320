import React, { createContext, useContext, useReducer } from 'react';

const initialState = {
  secretaryToken: localStorage.getItem('secretaryToken') || null,
  adminToken: localStorage.getItem('adminToken') || null,
  userToken: localStorage.getItem('userToken') || null,
  role: null,
};

const actions = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
};

const authReducer = (state, action) => {
  switch (action.type) {
    case actions.LOGIN:
      return {
        ...state,
        secretaryToken: action.payload.secretaryToken,
        userToken: action.payload.userToken,
        adminToken: action.payload.adminToken,
        role: action.payload.role,
      };
    case actions.LOGOUT:
      return {
        ...state,
        secretaryToken: null,
        adminToken: null,
        userToken: null,
        role: null,
      };
    default:
      return state;
  }
}


const AuthContext = createContext();

// AuthProvider component
export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>
  );
};

// Create the useAuth hook to access the context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
