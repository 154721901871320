import React from 'react';
import { BrowserRouter, Router, Route, Routes, Navigate } from 'react-router-dom';
import Intro from '../../components/CarvashComponents/Intro'
import Home from '../../components/CarvashComponents/Home';
const CarvashClient = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Intro />} />
                <Route path="/service/:id" element={<Home />} />
            </Routes>
        </>
    );
}

export default CarvashClient