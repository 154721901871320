import React from 'react'
import CarvashSidebar from './CarvashSidebar';
import { Routes, Route } from "react-router-dom";
import Settings from '../../components/CarvashComponents/Settings';
import Services from '../../components/CarvashComponents/Services';
import TypesCars from '../../components/CarvashComponents/TypesCars';
import TypeCarDetail from '../../components/CarvashComponents/TypeCarDetail';
import Reports from '../../components/CarvashComponents/Reports';
import ServiceDetail from '../../components/CarvashComponents/ServiceDetail';
import Brands from '../../components/CarvashComponents/Brands';
import BrandDetail from '../../components/CarvashComponents/BrandDetail';
import Home from '../../components/CarvashComponents/Home';




const CarvashDashboard = () => {
    return (
        <div className='dashboard-main'>
            <CarvashSidebar />
            <div className='dashboard-main-container'>
                <Routes>
                    <Route path="/" element={<Services />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/service-detail/:id" element={<ServiceDetail />} />
                    <Route path="/types" element={<TypesCars />} />
                    <Route path="/type-detail/:id" element={<TypeCarDetail />} />
                    <Route path="/brands" element={<Brands />} />
                    <Route path="/brand-detail/:id" element={<BrandDetail />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/reports" element={<Reports />} />

                </Routes>
            </div>
        </div>
    )
}

export default CarvashDashboard