import React, { useState } from 'react';

const Header = () => {
    return (
        <>
            <div className='header bg-blue'>
                <div className='d-flex h-100 px-48 justify-content-space-between align-items-center'>
                    <div className='fs-56 color-white fw-700'>خدمات مورد نظر خود را انتخاب کنید</div>
                    <div className='back-navbar-car fw-700'>بازگشت</div>
                </div>
            </div>
        </>
    );
};

export default Header;
