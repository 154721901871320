import React from 'react'

const Settings = () => {
  return (
    <div>
      <div className='dashboard-navbar space-between'>
        <div className='title-40 f-700'>تنظیمات</div>
      </div>

      <div className='table-container mt-24'>

        <div className='user-detail-container'>
          <h4 className='mb-4'>اطلاعات فروشگاه</h4>
          <div>

            <div className="form-content">
              <>

                <div className='d-flex'>
                  <div>
                    <div className='mb-6 mt-16 f-700'>نام فروشگاه</div>
                    <input type="text" name="first_name" className="input-form-dashboard-step mb-24 width-480" placeholder='نام فروشگاه' />
                  </div>
                </div>

                <div className='d-flex'>
                  <div>
                    <div className='mb-6 f-700'>نام و نام خانوادگی مدیر</div>
                    <input type="text" name="first_name" className="input-form-dashboard-step mb-24 width-480" placeholder='نام و نام خانوادگی مدیر' />
                  </div>
                </div>


                <div className='d-flex'>
                  <div>
                    <div className='mb-6 f-700'>شماره موبایل مدیر</div>
                    <input type="text" name="first_name" className="input-form-dashboard-step mb-24 width-480" placeholder='شماره موبایل مدیر' />
                  </div>
                </div>


                <div className='d-flex'>
                  <div>
                    <div className='mb-6 f-700'>شماره ملی مدیر </div>
                    <input type="text" name="first_name" className="input-form-dashboard-step mb-24 width-480" placeholder='شماره ملی مدیر ' />
                  </div>
                </div>


              </>
            </div>
          </div>
        </div>
      </div>


      <div className='table-container mt-24'>

        <div className='user-detail-container'>
          <h4 className='mb-4'>اطلاعات کیوسک</h4>
          <div>

            <div className="form-content">
              <>

                <div className='d-flex'>
                  <div>
                    <div className='mb-6 mt-16 f-700'>URL</div>
                    <input type="text" name="first_name" className="input-form-dashboard-step mb-24 width-480" placeholder='URL' />
                  </div>
                </div>

                <div className='d-flex'>
                  <div>
                    <div className='mb-6 f-700'>متن نمایشی در عنوان فاکتور</div>
                    <input type="text" name="first_name" className="input-form-dashboard-step mb-24 width-480" placeholder='متن نمایشی در عنوان فاکتور' />
                  </div>
                </div>

              </>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Settings