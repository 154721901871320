import React, { useEffect, useState } from 'react';
import logoImage from '../../assets/images/logo.png';
import onlineBlackImage from '../../assets/images/online-members-black.png';
import onlineWhiteImage from '../../assets/images/online-members-white.png';
import membersWhiteImage from '../../assets/images/members-white.png';
import membersBlackImage from '../../assets/images/members-black.png';
import logOutImage from '../../assets/images/logout-dashboard.png';

import reportBlackImage from '../../assets/images/report-black.png';
import reportWhiteImage from '../../assets/images/report-white.png';

import settingsBlackImage from '../../assets/images/settings-black.png';
import settingsWhiteImage from '../../assets/images/settings-white.png';

import hairServiceWhiteImage from '../../assets/images/hair-service-white.png';
import hairServiceBlackImage from '../../assets/images/hair-service-black.png';


import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const SalonSidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [userCount, setUserCount] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_URL}/customer/user-count-online`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
                    },
                });
                setUserCount(response.data.user_count);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();

        const intervalId = setInterval(() => {

            fetchData();
        }, 5000);

        return () => clearInterval(intervalId);
    }, []);


    const handleLogout = () => {
        localStorage.clear();
        navigate('/login-management');
    };

    const isServicesPath = /\/salon-dashboard\/services/.test(location.pathname);
    const isEmployeesPath = /\/salon-dashboard\/employees/.test(location.pathname);
    const isReportsPath = /\/salon-dashboard\/reports/.test(location.pathname);
    const isSettingsPath = /\/salon-dashboard\/settings/.test(location.pathname);


    return (
        <div className='dashboard-sidebar'>
            <div className='dashboard-sidebar-container'>

                <div className='sidebar-user-online'>
                    <div className='py-20 space-around flx-column justify-content-space-between height-100'>
                        <h2 className='color-white'>خوش آمدید</h2>
                        
                        <div>
                            <div className='fs-18 f-700'>آخرین همسانسازی:</div>
                            <div className='fs-18 f-700'>سه شنبه، ۱۴ خرداد | ساعت ۱۸:۳۰</div>
                        </div>
                    </div>
                </div>

                <div className='mt-24'>

                    <div className={`link-item fs-20 pointer ${isServicesPath ? 'link-item-active' : ''}`} onClick={() => navigate('/salon-dashboard/services')}>
                        <img src={isServicesPath ? hairServiceWhiteImage : hairServiceBlackImage} alt="members Images" className='ml-8' />
                        خدمات
                    </div>

                    <div className={`link-item fs-20 pointer ${isEmployeesPath ? 'link-item-active' : ''}`} onClick={() => navigate('/salon-dashboard/employees')}>
                        <img src={isEmployeesPath ? membersWhiteImage : membersBlackImage} alt="members Images" className='ml-8' />
                        کارمندان
                    </div>

                    <div className={`link-item fs-20 pointer ${isReportsPath ? 'link-item-active' : ''}`} onClick={() => navigate('/salon-dashboard/reports')}>
                        <img src={isReportsPath ? reportWhiteImage : reportBlackImage} alt="members Images" className='ml-8' />
                        گزارشات
                    </div>

                    <div className={`link-item fs-20 pointer ${isSettingsPath ? 'link-item-active' : ''}`} onClick={() => navigate('/salon-dashboard/settings')}>
                        <img src={isSettingsPath ? settingsWhiteImage : settingsBlackImage} alt="members Images" className='ml-8' />
                        تنظیمات
                    </div>
                </div>

                <div className='d-flex sidebar-logout pointer' onClick={handleLogout}>
                    <img src={logOutImage} alt="Logout Images" />
                    <h3 className='mr-12'>خروج از حساب کاربری</h3>
                </div>
            </div>
        </div>
    );
};

export default SalonSidebar;